@import "antd.css";

:root {
  --primary-color: #042446;
  --table-header-bg: #013366;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #e6e3ff;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  overflow-x: hidden;
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}
.rc-table-thead {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}
.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.sidebar-menu {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 80px);
  width: 240px;
  min-width: 220px;
  background: url("./assets/icons/sidebar_img.png") !important;
}

.ant-menu-inline-collapsed {
  width: 80px !important;
}

.sidebar-menu .ant-menu-sub {
  background-color: transparent !important;
}

.sidebar-menu .ant-menu-submenu > .ant-menu {
  max-height: 2000px !important;
  overflow: hidden;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ec9501 !important;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item-active {
  background-color: #ec9501 !important;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item .ant-menu-title-content {
  color: #f6fafc !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item
  .ant-menu-title-content
  a {
  color: #f9fbfc !important;
  font-family: "Roboto" !important;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item .ant-menu-item-icon {
  color: #f7f9f9 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu:not(.ant-menu-submenu-selected)
  .ant-menu-submenu-title
  .ant-menu-title-content {
  color: #f7f9f9 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu:not(.ant-menu-submenu-selected)
  .ant-menu-submenu-title
  .ant-menu-title-content
  a {
  color: #f7f9f9 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu:not(.ant-menu-submenu-selected)
  .ant-menu-submenu-title
  .ant-menu-item-icon {
  color: #f7f9f9 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-title-content {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-title-content
  a {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-item-icon {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active
  .ant-menu-title-content {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active
  .ant-menu-title-content
  a {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active
  .ant-menu-item-icon {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-open
  .ant-menu-submenu-title[aria-expanded="true"]
  .ant-menu-title-content {
  color: white !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-open
  .ant-menu-submenu-title[aria-expanded="true"]
  .ant-menu-item-icon {
  color: white !important;
}

.ant-tooltip-arrow::before {
  background-color: transparent !important;
}

.ant-tooltip {
  background-color: #5191d2 !important;
  margin: 0;
  padding: 0;
  border-radius: 8px;
}

.ant-tooltip-inner {
  background-color: transparent !important;
}

.ant-tooltip-inner a {
  color: #038fdd !important;
}

.ant-tooltip-inner a:hover {
  color: #fff !important;
}

.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background-color: "#5191d2";
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  background-color: #5191d2 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover {
  background-color: #ec9501 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content
  a {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-item-icon {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before {
  background-color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background-color: #fff !important;
}

.sidebar-menu .ant-menu-item::after {
  border-right: 0px !important;
}

.sidebar-menu .ant-menu-submenu-selected .ant-menu-item-icon {
  color: white !important;
}

.ant-menu-submenu-popup .ant-menu-sub {
  background-color: #013366 !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-submenu-popup .ant-menu-item-active {
  background-color: transparent !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content a {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-item-icon {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-active .ant-menu-title-content {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-active .ant-menu-title-content a {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-active .ant-menu-item-icon {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-submenu-active .ant-menu-submenu-title:hover {
  background-color: transparent !important;
}

.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content {
  color: #fff !important;
}
.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content
  a {
  color: #fff !important;
}
.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-item-icon {
  color: #fff !important;
}

.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before {
  background-color: #fff !important;
}

.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background-color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item::after {
  border-right: 0px !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
  width: 100% !important;
}

.ant-menu-vertical {
  max-height: 20000px !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-title {
  margin: 4px 0px;
  padding: 0px calc(50% - 20px / 2) !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
  margin: 4px 0px;
  padding: 0px calc(50% - 20px / 2) !important;
}

.sidebar-menu .ant-menu-item-icon {
  font-size: 20px !important;
}

/* 
.sidebar-menu .ant-menu-submenu-title{
  padding-left: 40px !important;
}

.sidebar-menu .ant-menu-item{
  padding-left: 40px !important;
}

.sidebar-menu .ant-menu-item-only-child .ant-menu-title-content{
  padding-left: 40px !important;

} */

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}
.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}
.ant-input-number,
.ant-picker {
  width: 100% !important;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #042446;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
::-webkit-scrollbar {
  /* width: 8px; */
  display: none;
}
@media all and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .ant-tabs-tab {




  font-family: Roboto-Medium;

} */
/* 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,

.ant-tabs-tab:hover {

  color: #042446 !important;

}

.ant-tabs-ink-bar {

  background: #042446 !important;

}
.ant-select-selector:hover{
  border-color: #042446 !important;

}
.ant-select-open{
  box-shadow:none;


}
.ant-select-focused{
  border-color: #042446 !important;
}
.ant-select, .ant-select-in-form-item, .ant-select-status-success, .ant-select-single, .ant-select-allow-clear, .ant-select-show-arrow, .ant-select-show-search{
  box-shadow:none
} */
/*
	 We are clearing the body's margin and padding, so that the header fits properly.
	 We are also adding a height to demonstrate the scrolling behavior. You can remove
	 these styles.
 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title,
.ant-drawer-close {
  color: #000000 !important;
}
.ant-drawer-close {
  display: none !important;
}
.ant-form-item-label > label {
  color: #000000 !important;
}

/* .anticon .anticon-close{ */
/* position: absolute !important;
color: #5383d3 !important;
right:2;
top: 2; */
/* } */
/* .ant-drawer-header-title{
  flex: 1 !important;
  color: #75b2f5 !important;
  position:absolute !important;
  align-items: center !important;
} */

/* .ant-btn .ant-btn-primary .ant-btn-dangerous{
  background-color: red !important;
} */

.form-card {
  background: #3c3c3c !important;
  border: none !important;
}

.form-card .ant-card-head {
  border-bottom: 1px solid #646464 !important;
}

.form-card .ant-card-head-title {
  color: white !important;
}

.form-card .ant-empty-description {
  color: white !important;
}

.form-card .ant-empty-img-default-path-5 {
  display: none !important;
}

.form-card .ant-empty-img-default-g {
  display: none !important;
}

.sbEntities-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: #d7d7d74d;
}

#sbEntities > div:last-child {
  opacity: 0;
  transition: opacity 200ms ease;
}

#sbEntities:hover > div:last-child {
  opacity: 1;
}

.access_rights_form label {
  color: black !important;
}

.ant-form-item-label > label:after {
  content: "" !important;
}

.access_rights_form .ant-form-item-label {
  width: 100px;
  text-align: left !important;
}

.access_rights_form .ant-form-item-control {
  flex: 1;
}

.horizontal_container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.horizontal_container::-webkit-scrollbar {
  display: none;
}

.ant-form .ant-form-vertical .user_form {
  color: #111;
}
.user_form label {
  color: #042446 !important;
}
.checkout-tabs {
  margin-left: 3rem;
  margin-right: 3rem;
}
.ant-form-horizontal .ant-form-item-label {
  width: 45% !important;
  text-align: left;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title {
  color: #fff;
}
.ant-drawer-close {
  color: #fff;
}

.date-pos {
  /* position: absolute;
  top: 0; */
  right: 10px;
  font-size: 12px !important;
  color: #000000;
}
.ant-btn .ant-btn-primary {
  background-color: dodgerblue !important;
  border: "none" !important;
}
/* .file-upload .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.operator-user {
  flex: 1;
  background-color: "#fff";
  height: "90vh";
  overflow-y: "auto";
}
.test {
  width: 400;
  position: fixed;
  top: 50px;
  background-color: #fef5fb;
  z-index: 2;
  align-items: center;
  margin-top: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}
.icon-size {
  width: 20px;
}

.sidebar-profile {
  margin-left: 10px !important;
  margin-bottom: 6px !important;
}

.sidebar-name {
  color: #038fdd !important;
  margin-left: 20px !important;
}

.ant-btn-primary {
  background: dodgerblue !important;
  border: "none" !important;
}

.ant-modal-close-x {
  margin-left: -17px !important;
}

.hospital-pagination {
  margin-left: auto;
  margin-top: 20px;
}

.hospital-search {
  min-width: 200px;
  max-width: 200px;
  margin-left: auto;
}
.primary-button {
  color: #fff !important;
  background-color: #036b2f !important;
  border-color: #036b2f !important;
}
.primary-button:hover {
  color: #f1f1f1;
  border-color: #036b2f;
}
.error-button {
  color: #fff !important;
  background-color: #bf0505 !important;
  border-color: #bf0505 !important;
}
.error-button:hover {
  color: #f1f1f1;
  border-color: #bf0505;
}

.ant-table ::-webkit-scrollbar {
  display: block;
  height: 7px;
  width: 7px;
}
.login_form {
  margin-bottom: 3px !important;
}
.ant-input-number-handler-wrap {
  display: none !important;
}

.selected-menu-item {
  background-color: yellow !important;
  color: white !important;
}

/* Custom CSS class for non-selected menu items */
.default-menu-item {
  color: inherit !important;
}

.ant-form-item-label > label {
  color: #000000 !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: start !important;
  gap: 4px !important;
}

.custom-date-box {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  background-color: #009688; /* Box background color */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px; /* Rounded corners if desired */
}

.custom-date-text {
  color: white; /* Text color */
  font-weight: bold; /* Text font-weight if desired */
  font-size: 16px; /* Text font-size if desired */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure the text takes up the entire width */
  height: 100%; /* Ensure the text takes up the entire height */
}

.calendar-day,
.calendar-date {
  width: 100px; /* Adjust the width to control the size of the squares */
  height: 100px; /* Adjust the height to control the size of the squares */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

.custom-calendar-table .ant-table-row {
  border: 1px solid #f10000; /* You can adjust the border style as needed */
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.dotted-divider {
  border-top: 2px dotted #888;
}

/* Style for the text on the right side of the divider */
.dotted-divider::after {
  content: attr(data-content);
  color: #888;
  padding: 0 10px;
  font-weight: bold;
}

.bordered-content {
  border: 1px solid #000;
}

.custom-tooltip {
  background-color: white !important;
  color: black !important;
  border-radius: "10px";
  /* border: 1px solid #ddd;  */
}
.ant-modal-header {
  background-color: #125c91 !important;
  color: rgb(255, 255, 255);
}
.ant-modal-title {
  color: white !important;
}

.ant-modal-close-x {
  color: "white" !important;
}

.odd-row {
  background-color: rgb(255, 255, 255); /* Background color for even rows */
}

.even-row {
  background-color: #fdeaea; /* Background color for odd rows */
}

/* Set the header background color */
.ant-table th {
  background-color: "lightblue" !important;
  color: "black";
}
.ant-table-thead > tr > th {
  background-color: #550a05c4 !important;
  color: white !important;
}

/* Set the background color for odd rows */
/* .ant-table-tbody > tr:nth-child(odd) {
  background-color: "grey";
} */

/* Set the background color for even rows */
/* .ant-table-tbody > tr:nth-child(even) {
  background-color: "lightgrey" !important;
} */
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: rgba(229, 236, 250, 0.7);
}

.table-striped-rows_expand tr:nth-child(2n) td {
  background-color: rgba(205, 223, 243, 0.7);
}

.table-striped-rows_expand tr:nth-child(2n + 1) td {
  background-color: rgba(212, 231, 252, 0.7);
}

/* custom-table.css */
.custom-table .ant-table-thead th {
  text-align: center;
  color: white !important;
}

/* Default background color for tr.ant-table-expanded-row */
tr.ant-table-expanded-row > td {
  background-color: rgb(
    237,
    237,
    237
  ) !important; /* Default background color (e.g., yellow) */
}

/* Change background color to green if the color prop is "lightgreen" */
tr.ant-table-expanded-row[data-color="lightgold"] > td {
  background-color: lightgreen !important;
}

.ant-modal-content {
  background-color: "lightblue" !important;
}

.ant-modal-header {
  background-color: "lightblue" !important;
}

.anticon .anticon-close .ant-modal-close-icon {
  color: #fff;
}

[data-custom-delete-modal="custom-delete-modal-header"] .ant-modal-header {
  background-color: #7f121f !important;
}
.mandrams_upload {
  display: flex;
  background-color: #009688;
  margin: 5px;
}

[data-table="static-header"] .ant-table th {
  position: fixed !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #fff !important;
}

.custom-row {
  height: 40px;
}

.custom-row-denomination {
  height: 40px;
}

@media Print {
  .print-content {
    border: 2px solid #000;
    padding: 10px;
  }
  .custom-row {
    height: 40px;
    color: "green";
  }
  .custom-row-denomination {
    height: 60px;
  }
}

.placeholder-black {
  color: rgb(33, 32, 32) !important;
  background-color: #ffde8b !important;
}
.placeholder-black::placeholder {
  color: #252525 !important;
}

.password-input > .ant-input::placeholder {
  color: rgb(15, 15, 15) !important;
}

[data-name-table="district-wise-report"] .ant-table-thead > tr > th {
  background-color: #275670 !important;
}
.count-detail-card {
  height: 120px;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin: 2%;
}

.mandram-count-detail-card {
  height: 130px;
  width: 200px;
  border-radius: 5px;
  color: white !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin: 2%;
}

.district-count-detail-card {
  height: 100px;
  width: 200px;
  border-radius: 5px;
  color: #1e2c55;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin: 2%;
}

.title {
  font-size: 0.9rem;
  text-align: center;
  margin: 0;
  color: white;
}

.count-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-family: "Roboto" !important;
}

.count-detail {
  text-align: center;
  margin: 8px;
}

.count-detail > h4 {
  color: white;
  font-weight: 600;
}

.count-detail > h5 {
  font-weight: 600;
  font-size: 12px;
  color: white;
}

.color-indicator-circle {
  height: 25px;
  width: 25px;
}

.date {
  font-size: 10px;
}
.color-indicator-text {
  font-size: 14px;
}

@media (max-width: 768px) {
  .count-detail-card {
    height: auto;
  }
  .date {
    font-size: 3px;
  }
  .color-indicator-text {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .color-indicator-text {
    font-size: 8px;
  }
  .date,
  .weekdays {
    font-size: 3px;
  }
  .count-detail > h5 {
    font-size: 2px;
  }
  .count-detail > h4 {
    font-size: 2px;
  }
  .color-indicator-circle {
    height: 20px;
    width: 20px;
  }
  .calendar-page {
    flex-direction: "column";
  }
}

@media (max-width: 930px) {
  .date,
  .weekdays,
  .count-detail > h5 {
    font-size: 7px;
  }
  .count-detail > h4 {
    font-size: 6px;
  }
}
@media (max-width: 998px) {
  .date,
  .weekdays {
    font-size: 7px;
  }
  .count-detail > h5 {
    font-size: 10px;
    font-weight: "bold";
  }
  .count-detail > h4 {
    font-size: 15px;
  }
}
.password-input > .ant-input {
  background-color: #ffde8b !important;
}
.ant-segmented-item ant-segmented-item-selected {
  background-color: #fe301d;
}

.main-calendar-container {
  height: "auto";
  margin: "5px";
  align-items: "flex-start";
  justify-content: "flex-start";
  background-color: #e4e5e5;
  border-top-left-radius: "10px";
  border-top-right-radius: "10px";
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.main-calendar-container > .week-days-container {
  display: flex;
  justify-content: space-between;
  background: #9e9e9c;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 5px;
}

.ant-popover-content {
  margin: 0px 0px -15px 0px !important;
}

.tooltip-content-container {
  padding: 5px;
  height: 90px;
  width: auto;
  margin-top: -2px;
  margin-bottom: 2px;
  border-radius: 8px;
  border: 1.5px solid lightgrey;
}
.input-placeholder::placeholder {
  color: #000 !important;
}
.placeholder-mobile::placeholder {
  color: #000 !important;
}
/* 
.ant-segmented-item-selected{
  border-radius: 20px !important;
  background-color: #fe301d   !important;
  color: white !important;
}
.ant-segmented{
  border-radius: 20px !important;
  background-color: #ffdd8a  !important;

} */

.count-detail-card {
  /* font-family: system-ui; */
}

.date_slots {
  width: 500px !important ;
}

@media print {
  .ant-table-pagination {
    display: none !important;
  }
}
.booking-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.booking-history-form {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: end;
}
.booking-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
}
.booking-history-form-second {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
}

.print-table {
  border: none;
}
.abishekam-date > .ant-select-selector > .ant-select-selection-placeholder {
  color: #000000 !important;
}
.district-place-holder
  > .ant-select-selector
  > .ant-select-selection-placeholder {
  color: #000000 !important;
}
.mandram-placeholder
  > .ant-select-selector
  > .ant-select-selection-placeholder
  > {
  color: #000000 !important;
}

.custom-fixed-column {
  opacity: 1;
}

.custom-table-billing-history .ant-table-thead > tr > th {
  opacity: 1;
  background-color: rgb(148, 67, 51) !important;
}
.custom-table-booking-action .ant-table-thead > tr > th {
  opacity: 1;
  background-color: rgb(148, 67, 51) !important;
}

.right-aligned-cell {
  text-align: right;
}

.aligned-cell {
  text-align: right;
}
.aligned-center {
  text-align: "center";
}

[data-payment-status="success"] .ant-modal-header {
  background-color: #125c91 !important;
  color: rgb(255, 255, 255);
}

[data-payment-status="failure"] .ant-modal-header {
  background-color: #913e12 !important;
  color: rgb(255, 255, 255);
}
.counter_report_placeholder
  > .ant-select-selector
  > .ant-select-selection-placeholder {
  color: #000 !important;
}

.ant-modal-close-icon {
  color: white !important;
}
.body-style {
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  text-align: center;
}
.header-style {
  border-style: solid;
  text-align: center;
  padding: 5px;
  font-weight: 500;
  border-color: lightgray;
  border-width: 1px;
  /* background-color: #275670; */
  color: black;
  font-weight: "bold";
}
.sub-header-style {
  /* border-style: solid; */
  border-bottom: "white";
  text-align: center;
  padding: 5px;
  font-weight: 500;
  border-color: lightgray;
  border-width: 1px;
  background-color: #275670;
  color: white;
}

@media Print {
  @page {
    counter-increment: page;
    content: "Page " counter(page);
  }
}

.text-left-align {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.text-right-align {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
