@media Print {
    .print-content {
      border: 2px solid #000; 
      padding: 10px; 
    }
    .custom-row  {
      height: 40px; 
      color:"green"
    }
  }
  @media print {
    .custom-row-denomination {
      border: 1px solid #000; 
    }
  }
  